import store from '@/store'
export  function getConfigureValue (key) {
        let obj = {};
        const frontendConfig = store.state.dynamic.frontendConfig;
        console.log(store.state.dynamic.frontendConfig,'000000000000');
        const configureItem = frontendConfig && frontendConfig.find(p => p.key === key)
        if (configureItem && configureItem.value) {
                obj = configureItem.value
        }
        return obj 
        
}