/**
 * @file
 * @author tanghao
 * @date 2019-06-19
 */

import Vue from 'vue';
import Vuex from 'vuex';
import app from './module/app';
import pages from './module/pages';
import coures from './module/coures';
import dynamic from '@wd/lowcode-ss-pc/plugin/store/index.js'; 
import api from "@/api";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        theme:'',
        courseCenterSearchName:'',
        pageConfigs: [],
        testPaper: '',
        subUserInfo:JSON.parse(localStorage.getItem('subUserInfo')) || {},
        userInfo:JSON.parse(localStorage.getItem('userInfo')) || {},
        areaList:[],
    },
    mutations: {
        //主题
        setTheme(state, payload){
            state.theme = payload
        },
        courseCenterSearchName(state, payload){
          state.courseCenterSearchName = payload
        },
        subUserInfo(state, payload){
          state.subUserInfo = payload;
        },
        userInfo(state, payload){
          state.userInfo = payload;
        },
        setPageConfigs(state, payload) {
            state.pageConfigs = payload;
        },
        // 机构级别
        saveSubUserInfo(state, payload) {
            state.subUserInfo = payload;
        },
        updateSubUserInfo(state,payload){
            state.subUserInfo[payload.key] = payload.value
        },
        // 城市列表
        saveCityTree(state,payload){
            state.areaList = payload
        }
    },
    actions: {
        // 获取个人信息(不加密的)
        getUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
            api.usercenter
                .getUserInfo({})
                .then((res) => {
                    if (res.data) {
                        localStorage.setItem("userInfo", JSON.stringify(res.data));
                        commit("userInfo", res.data);
                        resolve(res.data);
                    } else {
                        reject("获取失败");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
            });
        },
        // 加密的个人信息
        getUserInfoSecurity({ commit }) {
            return new Promise((resolve, reject) => {
                api.usercenter.getUserInfoSecurity({}).then((res) => {
                    if (res.data) {
                        localStorage.setItem("subUserInfo", JSON.stringify(Object.assign({},res.data)));
                        commit("subUserInfo", Object.assign({},res.data));
                        resolve(res.data);
                    } else {
                        reject("获取失败");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
            });
        },
        //配置
        getConfiguration(){
            return new Promise((resolve, reject) => {
                api.configuration.getConfiguration().then((res) => {
                    if (res.code === 0 && res.data) {
                        let frontendConfig = res.data.frontendConfig.filter(item => {
                            if (item.key === 'homepage') {
                              return true;
                            }
                          })[0];
                        localStorage.setItem('frontendConfig', frontendConfig ? JSON.stringify(frontendConfig) : '');
                        localStorage.setItem('theme', res.data.theme || '');
                        localStorage.setItem('configurationArr', JSON.stringify(res.data.frontendConfig))
                        localStorage.setItem('setTempConfigs', JSON.stringify(res.data)) 
                        localStorage.setItem('domain', res.data.solutionInfo.domain?JSON.stringify(res.data.solutionInfo.domain):'');
                        sessionStorage.setItem('branchId',res.data.branchId)
                        sessionStorage.setItem('saasName',res.data.solutionName)
                        sessionStorage.setItem('saasId',res.data.solutionInfo.rid);
                        resolve(res.data);
                    } else {
                        reject("获取失败");
                    }
                })
                .catch((err) => {
                    reject(err);
                });
            });
        },
    },
    getters: {
    },
    modules: {
        app,
        dynamic,
        pages,
        coures
    },
});
