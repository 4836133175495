export default API => ({
  // oss 上传文档的凭证
  getAliyunDocConf() {
    return API({
      url: '/standard-raas/v1/resource/getUploadDocVoucher',
      method: 'post'
    })
  },
  //   新接口
  getPlayAuthToken({
    data,
    params
  }) {
    return API({
      url: `/standard-raas/v1/resAccessToken/getPlayAuthToken`,
      data,
      params
    })
  },
  getNoLoginPlayAuth({
    data,
    params
  }) {
    return API({
      url: `/standard-raas/v1/resAccessToken/getNoLoginPlayAuth`,
      data,
      params
    })
  },
  getPlayAuthTokenInfo({
    data,
    params
  }) {
    return API({
      url: `/standard-raas/v1/resAccessToken/getPlayAuthTokenInfo`,
      data,
      params
    })
  },
  // 图片用老接口
  getImageOrDocUrl({
    data,
    params
  }) {
    return API({
      url: `/standard-raas/v1/resource/getImageOrDocUrl`,
      data,
      params
    })
  },
  // 新接口 文档
  getDocumentAccessToken({
    data,
    params
  }) {
    return API({
      url: `/standard-raas/v1/resAccessToken/getDocumentAccessToken`,
      data,
      params
    })
  },
  // 根据资源id 查看资源详情
  getSourseDetail({
    data,
    params
  }) {
    return API({
      url: `/standard-raas/v1/resource/findById`,
      params,
      data
    });
  },
  // 腾讯
  // 获取上传签名
  getUploadVideoSignature(data){
    return API({ url: `/resource/v1/resTencent/getUploadVideoSignature`, method: 'post', params: data})
  },
  // 上传
  uploadBusinessFile(data, headers={ "content-type": "multipart/form-data" }){
    return API({ url: `/standard-raas/v1/resource/uploadBusinessFile`, method: 'post', data,headers })
  },
  // 视频预览-腾讯
  previewMediasInfo(videoId) {
    return API({ url: `/standard-raas/v1/resAccessToken/getPlayAuthTokenInfo?resId=${videoId}`})
  },
  // 资源下载
  getDownloadUrl(params) {
    return API({ url: `/standard-raas/v1/resTencent/getDownloadUrl?resId=${params.id}`})
  },
  // 获取上传视频的id
  getUploadVideoParam(url) {
    return API({ url: `/standard-raas/v1/resource/createUploadVideo?${url}`, method:  'post' })
  },
  // 视频预览-阿里
  previewMedias(videoId) {
    return API({ url: `/standard-raas/v1/resAccessToken/getPlayAuthToken?resId=${videoId}`})
  },
  // 文档，图片url
  getImageOrDocUrls(id) {
    return API({ url: `/standard-raas/v1/resAccessToken/getDocumentAccessToken?resId=${id}`})
  },
  downFile(resId){
    return API({ url: `/standard-raas/v1/resource/downFile?resId=${resId}`})
  },
  refreshUploadVideo(url) {
    return API({ url: `/standard-raas/v1/resource/refreshUploadVideo?${url}` })
  },
  // oss上传获取凭证等图片
  getAliyunConf() {
      return API({ url: '/standard-raas/v1/resource/getUpFloadFileVoucher', method:  'post' })
  },

})