const urlName = "/cms/v1";
export default (API) => ({
    /**
     * 专题预览
     * @param {specialId:专题id} param0
     */
    getSpecialShow({ data }) {
        return API({
            url: `${urlName}/special/getSpecialShow`,
            method: "post",
            params: data,
            headers: {
                "content-type": "application/x-www-form-urlencoded",
            },
        });
    },
    /**
     * 文章列表
     * @param {classId:专题id} param0
     */
    getArticlesList({ params, data }) {
        return API({
            url: `${urlName}/article/list?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
            method: "POST",
            params: params,
            data: data,
            headers: {
                "Content-Type": "application/json",
            },
        });
    },

    /**
     * 文章详情new
     *
     */
    getArticleDetailNew(data) {
        return API({
            url: `/modular/admin/v1/block/getArticleInfo`,
            method: "post",
            data: data,
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
    /**
     * 文章详情
     *
     */
    getArticleDetail(params) {
        return API({
            url: `${urlName}/article/findById`,
            method: "get",
            params,
        });
    },

    getArticleByClass(data) {
        return API({
            url: `${urlName}/article/getArticleListForClassId`,
            method: "post",
            data: data,
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
    /**
     * 根据id 返回 视频 图片 详情
     *
     */
    getImageList(data) {
        return API({
            url: `${urlName}/article/getFiles`,
            method: "post",
            data: data,
        });
    },
});
