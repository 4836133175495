const urlName = '/ssusercenter/v1'

export default API => ({
  /**
   * 账号登录
   * @param { account: 账号, password: 密码 }  param2
   * 响应数据data：  用户token，用于标识用户的登录状态信息。
   */
  postAccountLogin ({ params, data }) {
    return API({ url: `${urlName}/account/login`, method: 'post', params, data });
  },
  /**
   * 验证码登录
   * @param { account: 账号, authCode: 密码 } param2
   * 响应数据data：  用户token，用于标识用户的登录状态信息。
   */
  postAccountLoginByAuthCode ({ params, data }) {
    return API({ url: `${urlName}/account/loginByAuthCode`, method: 'post', params, data });
  },
  /**
   * 注销登录
   * @param { token: 用户token } param1
   * 响应数据data：  boolean   注销登录的结构，true 成功；false 失败
   */
  postAccounLogout ({ params, data }) {
    return API({ url: `${urlName}/account/logout`, method: 'get', params, data });
  },
  /**
   * 获取验证码
   * @param { phone: 手机号 } param1
   * 响应数据data：  boolean   是否发送验证码成功，true 成功；false
   */
  postAccountGetAuthCode ({ params, data }) {
    return API({ url: `${urlName}/account/getAuthCode`, method: 'post', params, data });
  },
  /**
   * 修改密码
   * @param { password: 原密码, passwordNew: 新密码 } param2
   * 响应数据data：  boolean   修改密码的结果，true 成功；false 失败
   */
  postAccountChangePassword (params) {
    return API({ url: `${urlName}/account/changePassword`, method: 'post', params });
  },
  // 验证是否应该重新设置密码
  checkPasswordTime (params) {
    return API({ url: `${urlName}/account/checkPasswordTime`, method: 'get', params });
  },

  // 获取机构列表
  getFindUserBranchList ({ data, params }) {
    return API({
      url: `${urlName}/account/findUserBranchList`,
      data,
      params
    });
  },
  // 当前登录账号是否是管理员
  getIsAdmin () {
    return API({
      url: `${urlName}/administrator/isAdmin`,
    });
  },
  /**
   * 选择学员端机构
   * @param {}  
   */
  switchUserBranch ({ data, params }) {
    return API({
      url: `${urlName}/account/switchUserBranch`,
      data,
      params,
      method:'post'
    });
  },
  // 获取信息
  findByCurr() {
    return API({
      url: "/ssusercenter/v1/branch/findByCurr",
    });
  },
  
  /**
   * 修改密码（通过验证码改当前已登录账号）
   * @param { } 
   * 响应数据data：  
   */
  resetPassword4AuthCode (  {params,data} ) {
    return API({ url: `${urlName}/account/resetPassword4AuthCode`, method: 'post' , params,data});
  },
  /**
   * 修改密码（通过原密码改当前已登录账号）
   * @param { } 
   * 响应数据data：  
   */
  resetPassword4OldPassword (  {params,data} ) {
    return API({ url: `${urlName}/account/resetPassword4OldPassword`, method: 'post' , params,data });
  },
  /**
   * 获取验证码（根据当前已登录账号）
   * @param { } 
   * 响应数据data：  
   */
  getAuthCodeByCurr (  {params,data} ) {
    return API({ url: `${urlName}/account/getAuthCodeByCurr`, method: 'get' , params,data });
  },
  /**
   * 验证身份 校验验证码（根据输入手机号）
   * @param {} 
   * 响应数据data：  
   */
  checkAuthCode ( {params,data} ) {
    return API({ url: `${urlName}/account/checkAuthCode` , method: 'post' , params,data});
  },
  /**
   * 验证身份 校验验证码（根据当前已登录账号）
   * @param {} 
   * 响应数据data：  
   */
  checkAuthCodeByCurr ( {params,data} ) {
    return API({ url: `${urlName}/account/checkAuthCodeByCurr` , method: 'get' , params,data});
  },
  /**
  * 获取已绑定手机
  * 响应数据data：String
  */
  getCurrentUserAccount () {
    return API({ url: `${urlName}/account/getCurrentUserAccount` });
  },
  /**
  * 换绑手机号
  * 响应数据data
  */
  changePhone ({data,params}) {
    return API({ url: `${urlName}/account/changePhone`, method: 'post', data,params });
  },
  /**
  * 换绑手机号、邮箱发送验证码
  * 响应数据data
  */
  getAuthCodePublic ({ params, data }) {
    return API({ url: `${urlName}/account/getAuthCodePublic`, method: 'post', params, data });
  },
  /**
  * 更新个人资料
  * 响应数据data：{}
  */
  updateUser({params,data}) {
    return API({ url: `${urlName}/user/updateUser`, method: 'post', params,data });
  },
  /**
   * 获取个人资料 加密
   */
  getUserInfoSecurity ({ data, params }) {
    return API({
      url: `${urlName}/user/getUserInfoSecurity`,
      data,
      params,
      method:'get'
    });
  },
  /**
   * 获取个人资料 不加密
   */
  getUserInfo ({ data, params }) {
    return API({
      url: `${urlName}/user/getUserInfo`,
      data,
      params,
      method:'get'
    });
  },
  // 已经初始化设置过密码 
  alreadySetPassword({
    data,
    params
  }) {
    return API({
      url: `${urlName}/account/alreadySetPassword`,
      params,
      data,
      method:'post'
    });
  },
  // 判断是否是培训机构管理员
  isAdmin(){
    return API({ url: `${urlName}/administrator/isAdmin`}); 
  },
  // 是否实名
  postIsRealNameAuthentication ({ data, params }) {
    return API({ url: `/standard-saas/v1/user/oauth/isRealNameAuthentication`, method:'post', data, params })
  },
  // 初始化设置密码（注册后设置密码）
  initPassword ({ data, params }) {
    return API({ url: `${urlName}/account/initPassword`, method:'post', data, params })
  },
  // 更新当前账号设置密码时间
  updatePasswordTime ({ data, params }) {
    return API({ url: `${urlName}/account/updatePasswordTime`, method:'get', data, params })
  },
  alreadySetPasswordAndPasswordNotNull ({ data, params }) {
    return API({ url: `${urlName}/account/alreadySetPasswordAndPasswordNotNull`, method:'post', data, params })
  },
  /**
  * 申请注销
  * 响应数据data：Boolean
  */
  cleanAccount (params) {
    return API({ url: `${urlName}/account/cleanAccount`, method: 'post', params });
  },

    /**
   * 注册
   * @param { password: 密码, account: 账号, authCode: 验证码 } param1
   * 响应数据data：  boolean   修改密码的结果，true 成功；false 失败
   */
  postAccountRegister ({  data }) {
    return API({ url: `/ssusercenter/v1/account/register`, method: 'post',  data });
  },
    /**
   * 忘记密码
   * @param { authCode password phone} 
   * 响应数据data：  
   */
  resetPassword ({  data }) {
    return API({ url: `${urlName}/account/resetPasswordNew`, method: 'post' , data });
  },
  branchApplyRecord({data}){
    return API({ url: `/ssusercenter/v1/branchApplyRecord/save`, method: 'post' , data });
  },
  frontendFrontendList() {
    return API({
      url: "/modular/admin/v1/frontend/frontendList",
      method: "post",
    });
  },
})
