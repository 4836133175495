<template>
    <div class="gptFloating" v-if="showGpt" :class="[2,4].includes($route.meta?.gpt.gptType)?'':'redGpt'">
        <img @click="closeFn" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/gpt/close.png" alt="" class="close" v-if="showClose"/>
        <img @click="jump" :src="src" alt="" class="aiFloating" />
        <p>{{text}}</p>
    </div>
</template>

<script>
export default {
    name: 'GptFloating',
    props:{
        src:{
            default:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/custom.png',
            type:String
        },
        text:{
            default:'',
            type:String
        },
        showClose:{
            type:Boolean,
            default:true
        },
        cls:{
            default:'',
            type:String
        }
    },
    data() {
        return {
            showGpt: true,
        }
    },
     watch: {
        $route() {
            this.showGpt = true;
        },
    },
    methods: {
        closeFn() {
            this.showGpt = false
        },
        jump() {
            this.$emit('openAi')
        },
    },
}
</script>

<style lang="scss" scoped>
.gptFloating {
    position: fixed;
    bottom: 162px;
    right: 80px;
    width: 75px;
    height: 75px;
    z-index:30;
    text-align: center;
    .close {
        width: 16px;
        height: 16px;
        position: absolute;
        right: -12px;
        top: -6px;
        cursor: pointer;
    }
    .aiFloating {
        width: 100%;
        cursor: pointer;
        height: 100%;
    }
    p{
        margin-top:5px;
        background:#1A72FF;
        padding:5px 12px;
        border-radius:32px;
        color:#fff;
        white-space: nowrap;
    }
}
div.redGpt{
    p{
        background: linear-gradient( 180deg, #FD8E90 0%, #FD4042 100%);
    }
}
</style>
