<template>
    <div class="saas-nsr-footer jm" :style="{background: '#dbdbdb'}">
        <!-- 0211edit -->
        <div class="botmtxt" v-if="newSaasNsrFooter">
            <div class="txt1">版权所有： {{copyright}}</div>
            <div class="txt2" @click="jump('https://beian.miit.gov.cn/#/Integrated/recordQuery')"><img class="national_icon" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/icon/national-emblem.png"/>京ICP备10026535号-15</div>
            <div class="txt3">技术支持：伟东云教育集团</div>
        </div>
        <div class="preBottomTxt" v-else>
            <p class="txt" :style="{color:textColor}" >
                <span  v-if="isContract" @click="showContract=true" class="point">
                    联系我们
                </span>
                <span @click="go('privacy')" class="point">
                    法律声明及隐私政策
                </span>
                <span>
                    违法和不良信息举报电话： 400-8555-686
                </span>
            </p>
            <p :style="{color:textColor}">
                <span>
                    {{copyright || '©2020-2024 wdeduc.com 版权所有'}}
                </span>
                <span @click="jump('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802035145')" class="point">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/icon/national-emblem.png"/>
                    <b>{{nsrNumber || '京公网安备 11010802035145号'}}</b>
                </span>
                <span @click="jump('https://beian.miit.gov.cn/#/Integrated/recordQuery')" class="point">
                    {{ICPNumber || '京ICP备10026535号-30'}}
                </span>
                <span v-if="showLicence">
                    京ICP证161318号
                </span>
                <span v-if="showText1">
                    京网文{2018}2746-280号
                </span>
            </p>
        </div>
       
        <!--联系我们-->
        <el-dialog
            title="联系我们"
            :visible.sync="showContract"
            width="470"
        >
            <div class="contract-info">
                <p><span>地址：</span> 北京市西城区北三环中路燕莎盛世大厦5层</p>
                <p><span>邮编：</span> 100029</p>
                <p><span>电话：</span> 400-8555-686</p>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "saas-nsr-footer",
  props: {
    bgColor: {
      default: "#202736",
      type: String
    },
    isContract: {
      type: Boolean,
      default: true
    },
    copyright: {
      type: String,
      default: ""
    },
    nsrNumber: {
      type: String,
      default: ""
    },
    ICPNumber: {
      type: String,
      default: ""
    },
    textColor: {
      type: String,
      default: "#fff"
    },
    showLicence: {
      type: Boolean,
      default: true
    },
    showText1: {
      type: Boolean,
      default: true
    },
    newSaasNsrFooter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showContract: false
    };
  },
  methods: {
    jump(url) {
      window.open(url, "newW");
    },
    go(url) {
      const { href } = this.$router.resolve({
        path: `/${url}`
      });
      window.open(href, "newW");
    }
  }
};
</script>
<style scoped lang="scss">
.saas-nsr-footer {
    padding: 0 0;
    &.jm{
        padding: 0 0!important;
    }
    .preBottomTxt{
        padding:30px 0;
    }
    .botmtxt {
        height: 52px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4D4D4D;
        line-height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;

        .txt2 {
            display: flex;
            align-items: center;
            margin-left: 30px;
            margin-right: 30px;
            cursor: pointer;

            .national_icon {
                width: 16px;
                height: 15px;
            }
        }
    }

    p {
        justify-content: center;
        display: flex;
        line-height: 20px;
        font-size: 14px;
        color: #fff;

        &.txt {
            margin-bottom: 30px;
        }

        span {
            padding: 0 30px;

            b {
                font-weight: normal;
            }

            img {
                width: 21px;
                height: 22px;
                margin-right: 6px;
                vertical-align: text-top;
            }
        }

        .point {
            cursor: pointer;
        }
    }

    ::v-deep .el-dialog {
        border-radius: 18px;
        width: 470px;
    }

    ::v-deep .el-dialog__header {
        background: #F9FAFF;
        border-radius: 18px 18px 0 0;
        padding: 0 20px;
        line-height: 49px;
    }

    ::v-deep .el-dialog__title {
        font-size: 18px;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 25px;
    }

    ::v-deep .el-dialog__headerbtn {
        top: 2px;

        i {
            font-size: 22px;
            color: #79889A;
            font-weight: bold;
        }
    }

    .contract-info {
        p {
            color: #6B6B6B;
            font-size: 16px;
            line-height: 40px;
            display: block;

            span {
                color: #000;
                font-size: 16px;
                line-height: 40px;
            }
        }
    }
}
</style>