const Routers = require.context("./../modules/", true, /\.router\.js$/);
const wdnRouters = require.context('ss-pc/src/modules', true, /\.router\.js$/);

let RouterArr = [];
let RouterArr2 = [];

Routers.keys().forEach((key) => {
  RouterArr = [...RouterArr, ...Routers(key).default];
});

RouterArr.forEach((item) => {
  item.meta.highlight = item.path;
});
wdnRouters.keys().forEach(key => {
  RouterArr2 = [
    ...RouterArr2,
    ...wdnRouters(key).default
  ]
})
const route = [
    {
      path: '/course/play',
      name: 'coursePlay',
      meta: { title:'视频' ,
        login:true,
        gpt:{
          gptType:2,
          text:'课代表',
          imgUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ai.png'
        } 
      },
    component: () => import(/* webpackChunkName: 'coursePlay' */ 'ss-pc/src/modules/coursePlay/page/indexNew.vue'),
  },
  {
    path: '/course/detail',
    name: 'courseDetail',
    meta: { title:'视频' ,
      login:false,
      gpt:{
        gptType:4,
        text:'课程助手',
        imgUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ai.png'
      } 
    },
  component: () => import(/* webpackChunkName: 'course' */ 'ss-pc/src/modules/courseCenter/page/detail.vue'),
},
  ...RouterArr, // saas定制的页面,以及需要路由覆盖的页面
  ...RouterArr2, // 大平台的页面引入
];
console.log(121212, route);
export default route;
