
const routerArr = [
  // {
  //     path: '/',
  //     meta: { title:'登录' , login:false },
  //     redirect: { path: '/login' },
  //     component: () => import(/* webpackChunkName: 'entrance' */ './page/login.vue'),
  // },
  {
      path: '/login',
      name: 'login',
      meta: { title:'登录' , login:false },
      component: () => import(/* webpackChunkName: 'entrance' */ './page/login.vue'),
  },
{
    path: '/feRegister',
    name: 'feRegister',
    meta: { title:'政府注册' , login:false },
    component: () => import(/* webpackChunkName: 'entrance' */ './page/feRegister.vue'),
},
{
    path: '/register',
    name: 'register',
    meta: { title:'注册' , login:false },
    component: () => import(/* webpackChunkName: 'entrance' */ './page/register.vue'),
},
// {
//     path: '/register/ldt',
//     name: 'registerLdt',
//     meta: { title:'企业注册' , login:false },
//     component: () => import(/* webpackChunkName: 'entrance' */ './page/register-enterprise.vue'),
// },
// {
//     path: '/register/govt',
//     name: 'registerGovt',
//     meta: { title:'政府注册' , login:false },
//     component: () => import(/* webpackChunkName: 'entrance' */ './page/register-government.vue'),
// },
{
    path: '/forget',
    name: 'forget',
    meta: { title:'忘记密码' , login:false },
    component: () => import(/* webpackChunkName: 'entrance' */ './page/forget.vue'),
},
]
export default routerArr
