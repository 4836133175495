import Vue from "vue";
import Router from "vue-router";
import api from "./../api";
import store from "@/store/index";
import routes from "./routers";
import isOfficial from '@/utils/common'

Vue.use(Router);
const router = new Router({
  mode: "history",
  routes,
  scrollBehavior: () => ({ y: 0 }),
});
// 大平台导航头部
const navName = ["home", "learnList", "courseList", "lecturerList", "personal"];
let frontendConfig = null;
router.beforeEach(async (to, from, next) => {
  if (to.query.s || (to.query.token&&to.query.token!=='null')) {
    // s == token
    localStorage.clear();
    localStorage.setItem("token", to.query.s || to.query.token);
    const banchListData = await api.usercenter.getFindUserBranchList({});
    if (banchListData.data && banchListData.data.length) {
      localStorage.setItem(
        "UserBranchList",
        JSON.stringify(banchListData.data)
      );
      localStorage.setItem(
        "branchUcode",
        JSON.stringify( banchListData.data[0].ucode)
      );
      const params = {
        ucode: banchListData.data[0].ucode,
      };
      const res = await api.usercenter.switchUserBranch({ params });
      if (res.success) {
        await store.dispatch("getUserInfo");
        await store.dispatch("getUserInfoSecurity");
        next(to.path);
      }
    }
    // next(to.path)
  }
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";
  // 获取配置参数【主题，页面配置】
  if (!frontendConfig) {
    await api.configuration.getConfiguration().then((res) => {
      if (res.code === 0 && res.data) {
        frontendConfig = res.data.frontendConfig.filter((item) => {
          if (item.key === "homepage") {
            return true;
          }
        })[0];
        console.log(
          res.data.solutionInfo.domain,
          "res.data.solutionInfo.domain"
        );
        localStorage.setItem(
          "domain",
          res.data.solutionInfo.domain
            ? JSON.stringify(res.data.solutionInfo.domain)
            : ""
        );
        localStorage.setItem("theme", res.data.theme || "");
        localStorage.setItem(
          "frontendConfig",
          frontendConfig ? JSON.stringify(frontendConfig) : ""
        );
        localStorage.setItem(
          "configurationArr",
          JSON.stringify(res.data.frontendConfig)
        );
        localStorage.setItem("setTempConfigs", JSON.stringify(res.data));
        sessionStorage.setItem("branchId", res.data.branchId);
        sessionStorage.setItem("saasName", res.data.solutionName);
        sessionStorage.setItem("saasId", res.data.solutionInfo.rid);
        document.title = res.data.solutionName;
        if (res.data.websiteSetting && res.data.websiteSetting.pageLogo) {
          let link = document.querySelector("link[rel*='icon']");
          link.href = res.data.websiteSetting.pageLogo;
          localStorage.setItem("pageLogo", res.data.pageLogo);
        }
        if (res.data.websiteSetting && res.data.websiteSetting.pageTitle) {
          document.title = res.data.websiteSetting.pageTitle;
          localStorage.setItem("pageTitle", res.data.pageTitle);
        }
      }
    });
  }
  //   解决导航头选中问题
  if (!navName.includes(to.name)) {
    to.meta.highlight = from.meta.highlight;
  }
  console.log(
    store.state.courseCenterSearchName,
    "Store.state.courseCenterSearchName"
  );
  if (to.path != "/course/list") {
    store.commit("courseCenterSearchName", "");
  }

  if (to.path !== "/exam/testPaper") {
    sessionStorage.removeItem("submissionTime");
    sessionStorage.removeItem("secondsExam");
    sessionStorage.removeItem("allowCommitDurationExam");
  }
  // if(token && to.path === '/login'){//避免重复登录
  //   next('/'+frontendConfig.value)
  // }

  // if (frontendConfig && frontendConfig.value && to.path === '/') {//配置的首页路由
  //   next('/'+frontendConfig.value)
  // }
  if(token && (localStorage.getItem('identity')!='user') && (!localStorage.getItem('branchName'))){
    await store.dispatch("getUserInfo");
    await store.dispatch("getUserInfoSecurity");
    findByCurr();
  }
  if(!isOfficial){
    localStorage.setItem('identity','user')
  }
  if(to.path=='/' && isOfficial){
    next('/home');
  }else{
    next();
  }
  if (to.meta?.login && !token) {
    let pathname =
      window.location.pathname && window.location.pathname.replace("/", "");
    let search = window.location.search;
    let backPath = "";
    if (search) {
      backPath = `${search}&name=${pathname}`;
    } else {
      backPath = `?name=${pathname}`;
    }
    if (backPath) {
      next(`/login${backPath}`);
    } else {
      next("/login");
    }
  } else if (to.meta && !to.meta.login) {
    //不需要登录
    console.log("222222222222222=======");

    next();
  } else {
    if (token) {
      console.log("33333333333=========");

      next();
    }
  }
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});
async function findByCurr(){
  const res = await api.usercenter.findByCurr();
  if (res.data) {
    localStorage.setItem('branchName', res.data?.name)
    localStorage.setItem('branchUcode', res.data?.ucode)
  }
}
export default router;
