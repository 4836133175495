export default [
  {
    path: "/staff/train",
    name: "staff",
    meta: {
      weight: 0,
      login: false,
    },
    component: () =>
      import(/* webpackChunkName: 'staff' */ "./page/index.vue"),
  },
  {
    path: "/staff/orienteering",
    name: "orienteering",
    meta: {
      weight: 0,
      login: false,
    },
    component: () =>
      import(/* webpackChunkName: 'staff' */ "./page/orienteering.vue"),
  },
  {
    path: "/staff/other",
    name: "other",
    meta: {
      weight: 0,
      login: false,
    },
    component: () =>
      import(/* webpackChunkName: 'staff' */ "./page/other.vue"),
  }
];
