import Axios from 'axios';
import URL from '../config/url';
import resetMessage  from './resetMessage';
const baseURL = URL.API;

const service = Axios.create({ withCredentials: false, baseURL })
const tipMessage = ['旧密码输入错误', '已经实名认证过，不允许修改用户姓名', '验证码不能为空', '手机号已经绑定到其他账号，请更换手机号']

// 请求拦截器
service.interceptors.request.use(config => {
  localStorage.getItem('token') && (config.headers['token'] = localStorage.getItem('token'))
  return config
}, err => {
  return Promise.reject(err)
})

// 响应拦截器
service.interceptors.response.use(config => {
  return config
}, err => {
  return Promise.reject(err)
})
function API (config) {
  const {
    url = '',
    data = null,
    params = null,
    headers = { "content-type": "application/json" },
    method = 'get',
    isToast = true,
    timeout = 10000,
  } = config || {}
  return new Promise((resolve, reject) => {
    service({
      method, // 请求方式
      url, // 请求路径
      timeout, // 请求超时
      headers, // header体
      data, // body参数
      params, //  URL参数
      isToast//是否显示toast提示
    }).then(res => {
      if (res.data.code && res.data.code !== 302 && res.data.code !== 50001 && isToast){
        if (tipMessage.includes(res.data.message)) {
          resetMessage({
            showClose: true,
            message: res.data.message,
            type: 'warning',
          })
        } else {
          resetMessage({
            showClose: true,
            message: res.data.message,
            type: 'error',
          })
        }
      }
      if (+res.data.code === 303 || +res.data.code === 10000) {
        if(window.location.pathname === '/login') return
        let pathname = window.location.pathname && window.location.pathname.replace('/','')
        let search = window.location.search
        let backPath = ''
        if(search){
          backPath = `${search}&name=${pathname}`
        }else{
          backPath = `?name=${pathname}`
        }
        localStorage.removeItem('token');
        window.location = `//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/login${backPath}`
        // window.location = `//${window.location.host}/login`
        resolve('跳转登录页')
      }
      resolve({
        ...res.data,
        headers: res.headers
      })
    }).catch(err => {
      reject(err)
      if(err && err.message && isToast){
        if(err.message === 'Network Error' || err.message.includes('timeout')){
          resetMessage({
              showClose: true,
              message:`网络异常，请重新尝试`,
              type: 'error'
          })
        }
      }
    })
  })
}

export default API
