<template>
    <div>
        <msg position="right" :avatar="subUserInfo?.headImage || myAvatar" :content="MsgInfo.send_msg" :rightObj="MsgInfo"/>
        <msg position="left" :avatar="gptAvatar" :content="MsgInfo.receive_msg" :MsgInfo="MsgInfo" :isSendFlag="isSendFlag"/>
    </div>
</template>

<script>
import msg from './msg'
export default {
    name: 'MsgBox',
    components: { msg },
    computed: {
        subUserInfo() {
            return this.$store.state.subUserInfo
        },
    },
    props: {
        MsgInfo: {
            type: Object,
            default() {
                return {}
            },
        },
        isSendFlag:{
            type:Boolean,
            default:true
        }
    },
    data() {
        return {
            // 我的头像
            myAvatar: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/default-avatar.png',
            // ai头像
            gptAvatar: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/gpt/ai.png',
        }
    },
    methods: {},
}
</script>

<style lang="scss" scoped></style>
