
export default API => ({
  /**
  * 获取城市列表
  * 响应数据data：{}
  */
  getTree () {
    return API({ url: `/ssusercenter/v1/region/getTree` });
  },
  // 获取账号对应的域名
  getdomainsByBranchId (params) {
    return API({ url: `/modular/admin/v1/frontend/getdomainsByBranchId`,params });
  },
  getdomainsByBranchUcode (params) {
    return API({ url: `/modular/admin/v1/frontend/getdomainsByBranchUcode`,params });
  }
})
  
