const gptTypeRecommend = {
    //  1:首页推荐 2：课程详情推荐
    1: [
        {
            question: "高效职业化的沟通管理课程中讲了什么？",
        },
        {
            question: "大客户关系维系要怎么做？",
        },
        {
            question: "除了抖音,还有哪些营销渠道？",
        },
    ],
    2: [
        {
            question: "课程主要讲了什么？",
        },
        {
            question: "请把课程大纲发给我",
        },
    ],
};

// ai课代表各页面推荐数据
const GPT_TYPE_RECOMMEND = {
    //  1:首页推荐 2：课程详情推荐
    1: [
        {
            question: "安全生产法相关课程推荐有哪些？",
        },
        {
            question: "道路运输法规、政策怎么学习？",
        },
        {
            question: "怎样做好职业病危害预防和管理？",
        },
    ],
    2: [
        {
            question: "我该怎样学会这门课？",
        },
        {
            question: "这门课程学习要点有哪些？",
        },
        {
            question: "这门课程学习大纲是什么？",
        },
    ],
    3:[
        {
            question: "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-h5/guzhangrecommend1.png",
        },
        {
            question: "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-h5/guzhangrecommend2.png",
        },
        {
            question: "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-h5/guzhangrecommend3.png",
        }
    ],
    4: [
        {
            question: "我该怎样学会这门课？",
        },
        {
            question: "这门课程学习要点有哪些？",
        },
        {
            question: "这门课程学习大纲是什么？",
        },
    ],
    
};
export default {
    gptTypeRecommend,
    GPT_TYPE_RECOMMEND,
};
