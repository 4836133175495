export default {
    data() {
        return {
            maxSize: 1024 * 1024 * 500,
            fileNum: 1,
            ossClient: null, // oss客户端实例
            counts: 0,
            datas: [],
            list: [],
            bucketName: "",
        };
    },
    mounted() { },
    methods: {

        // 创建OSS Client
        async initOSSClient(datas) {
            const OSS = require("ali-oss");
            // this.bucketName = datas.data.privateBucket;
            this.bucketName = datas.data.bucket || datas.data.bucketName
            this.ossClient = new OSS({
                region: datas.data.region,
                accessKeyId: datas.data.accessKeyId,
                accessKeySecret: datas.data.accessKeySecret,
                bucket: this.bucketName /* 装图片的桶名 */,
                stsToken: datas.data.securityToken,
                endpoint: datas.data.endpoint, // 新增加
            });
        },
        // 上传客户见证
        async handleClickUpload(file) {
            this.datas = await this.$api.resource.getAliyunConf();
            return new Promise((resolve, reject) => {
                this.initOSSClient(this.datas).then(() => {
                    const storeAs = this.creatFileUrl(file.name);
                    this.ossClient
                        .multipartUpload(storeAs, file, {
                            progress: async (p, checkpoint) => {
                                console.log(p);
                                console.log(checkpoint);
                            },
                        })
                        .then((result) => {
                            console.log(result);
                            resolve(result);
                        })
                        .catch((e) => {
                            console.log(e);
                            reject(e);
                        });
                });
            });
        },


        creatFileUrl(name) {
            const date = new Date();
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const timestamp = new Date().getTime();
            const fileSuffix = name.lastIndexOf(".");
            const fileExt = name.substring(fileSuffix); // 后缀名
            const storeAs = `${timestamp}${fileExt}`;
            return `${year}/${this.add0(month)}/${storeAs}`;
        },
        add0(m) {
            return m < 10 ? `0${m}` : m;
        },
    },
};
