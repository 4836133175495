<template>
    <div class="chatGpt" :class="($route.meta?.gpt?.gptType!=2)?'redGpt':''">
        <div class="gtpBox">
            <div class="contentBox">
                <div>
                    <header>
                        <div class="header-left">
                            <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/gpt/ai.png" /> -->
                            <span class="title">{{gptText || '课代表'}}</span>
                            <span class="tag">答案由AI生成 仅供参考</span>
                        </div>
                        <span @click="closeDrawer">
                            <svg-icon  icon-class="close"></svg-icon>
                        </span>
                    </header>
                </div>
                <div id="scrollbar">
                    <el-scrollbar class="chatListBox">
                        <div class="defaultQuestion" v-if="show_preset_question == 1">
                            <div class="header">其他用户都在问什么?</div>
                            <!-- 名师 -->
                            <div class="ul">
                                <div class="li" v-for="(item, index) in questionRecommendList" :key="index" @click="changeRecommend(item.question)">
                                    <span v-if="chat_type!=3">{{ item.question }}</span>
                                    <img v-if="chat_type == 3" :src="item.question" />
                                    <span class="btn" v-if="chat_type==3">看解析</span>
                                    <span class="btn" v-else>问一问</span>
                                </div>
                            </div>
                        </div>
                        <div class="chatList">
                            <MsgBox v-for="(item, index) in msgList" :key="index" :MsgInfo="item" :isSendFlag="isSendFlag"/>
                        </div>
                    </el-scrollbar>
                </div>
                <div class="chat-input-container" ref="chatInputBox">
                    <div class="functionBlock">
                        <!-- <div class="recommend">
                            <el-switch v-model="courseRecommendFlag" :disabled="isSendFlag" active-color="#1A72FF" inactive-color="#ff4949" />
                            <span>推荐课程</span>
                        </div> -->
                        <div class="clear" @click="clearContext" :class="isSendFlag?'disableClear':''">
                            <svg-icon icon-class="clear"></svg-icon>
                            <el-button type="text" :disabled="isSendFlag">清除历史记录</el-button>
                        </div>
                    </div>
                    <div class="chat-input-textarea">
                        <div class="chat-input">
                            <textarea
                                v-if="chat_type==1 || chat_type==2 || chat_type==4 || (chat_type==3 && msgList && msgList.length>0)"
                                ref="inputTextarea"
                                v-model="message"
                                :disabled="isSendFlag"
                                @input="adjustTextareaHeight"
                                @keydown.enter="handleEnterKey"
                                @compositionstart="onCompositionStart"
                                @compositionend="onCompositionEnd"
                                placeholder="有什么问题尽管问我"
                            />
                            <div class="operateBox" >
                                <div v-show="isOpenVoice" class="recwaveVP">
                                    <div class="recwaveV" ref="recwave"></div>
                                </div>
                                <!-- <div class="sendBox" @click="voiceIconClick" v-if="chat_type!=3">
                                    <svg-icon icon-class="voice" :className="isSendFlag?'disabledIcon':'active'" v-if="!isOpenVoice"></svg-icon>                                </div>
                                <div class="sendBox" @click="voiceIconStop" v-if="chat_type!=3">
                                     <svg-icon icon-class="stop" :className="isSendFlag?'disabledIcon':'active'" v-if="isOpenVoice"></svg-icon>
                                </div> -->
                                <div class="sendBox" v-if="chat_type==3">
                                    <el-upload
                                        :disabled="isSendFlag"
                                        action=""
                                        class="upload-demo"
                                        :multiple="false"
                                        :limit="1"
                                        :headers="header"
                                        :show-file-list="false"
                                        :before-upload="beforeRead"
                                        accept="image/*"
                                        :http-request="afterRead"
                                    >
                                        <svg-icon icon-class="ai-img" :className="isSendFlag?'disabledIcon':'active'"></svg-icon>
                                    </el-upload>
                                </div>
                                <div class="sendBox" @click="sendMessage(1, message, '')">
                                    <svg-icon icon-class="send" :className="isSendFlag?'blue-icon disabledIcon':'active blue-icon'"></svg-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fetchEventSource } from '@microsoft/fetch-event-source'
import MsgBox from '../components/MsgBox.vue'
import { AutoScrollMixin } from './AutoScrollMixin'
import URL from '@/config/url'
// 必须引入核心
import Recorder from 'recorder-core';
// 引入mp3格式支持文件
import 'recorder-core/src/engine/mp3';
import 'recorder-core/src/engine/mp3-engine';

// 引入wav格式支持文件
import 'recorder-core/recorder.wav.min.js';

// 可选的插件支持 这个是波形可视化插件
import 'recorder-core/src/extensions/waveview';
import uploadOss from "../libs/uploadOss";

export default {
    components: { MsgBox },
    mixins: [AutoScrollMixin,uploadOss],
    props:{
        gptText:{
            type:String,
            default:'AI名师'
        },
        gptType:{
            type:Number,
            default:1
        },
        contentId:{
            type:Number,
            default:1
        },
    },
    data() {
        return {
            sn: '', // 唯一标识
            session_id: '',
            defaultQuestion: true, // 是否显示默认问题
            refreshing: false, // 下拉加载状态
            NoMore: false, // 是否没有历史消息了
            courseRecommendFlag: false, // 课程推荐开关
            courseList: [], // 推荐的课程list
            msgList: [], // 消息list
            questionRecommendList: [], // 推荐的话术
            message: '', // 发送的消息文本
            isSendFlag: false, // 是否正在发送消息
            compositiLock: false, // 中文输入法的锁-解决有拼音的时候 回车直接发送消息
            maxTextareaHeight: 180, // 文本区域的最大高度
            show_preset_question:1,//是否显示预设问题
            rec: null,
            wave: null,
            isOpenVoice: false,
            locaAudiolUrl: "",
            uploadLoading: false,
            lastData: {},// json最后的推荐课程或是音频
            startTime: 0,
            endTime: 0,
            arr: null,
            timer: null,
            header:{
                token:localStorage.getItem('token')
            },
            chat_type: 1, //类型: 1-通用助手(aI名师) 2-视频助手（学习中心） 3-隐患排查助手
        }
    },
    created() {
        const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
        this.session_id = userInfo.phone;
        this.chat_type = this.gptType;
        this.getQuestionRecommend()
        // this.showPresetQuestion()
    },
    mounted() {
        this.getHistory().then(() => {
            this.scrollbar.scrollTop = this.scrollbar.scrollHeight
        })
    },
    beforeDestroy(){
        this.rec && this.rec.close();
    },
    methods: {
        afterRead(file) {
            // 此时可以自行将文件上传至服务器
            let formData = new FormData();
            formData.append("file", file.file);
            this.handleClickUpload(file.file).then((res) => {
                console.log(res.res.requestUrls[0], '图片上传成功++++++',)
                const str = res.res.requestUrls[0];
                const length = str.indexOf("?");
                let url = str;
                if(str && (length>-1)){
                    url = str.substring(0,length)
                }
                this.sendMessage(2, url, '');
            });
        },
        beforeRead(file) {
            this.uploadLoading = true;
            if (file.type.indexOf("image") === -1) {
                this.$notify({
                    duration: 2000,
                    type: "danger",
                    message: "请上传正确格式的图片",
                });
                this.uploadLoading = false;
                return false;
            }
            return true;
        },
        voiceIconStop() {
            this.recStop();
            this.isOpenVoice = false;
        },
        // 请求权限
        voiceIconClick() {
            if (this.isOpenVoice) {
                this.voiceIconStop();
                return;
            }
            this.isOpenVoice = true;
            this.recOpen();
            let that = this;
            setTimeout(() => {
                that.recStart();
            }, 1000);
        },
         // 请求权限
        recOpen() {
            this.rec = Recorder({
                type: 'wav',//录音格式，可以换成wav等，但是注意格式与后端webserver的格式要一致
                bitRate: 16,// 录音比特率，越大细节越清晰，但是文件越大
                sampleRate: 16000, // 录音采样率，一般有8000, 16000, 44100等
                onProcess: (buffers, powerLevel, bufferDuration, bufferSampleRate) => {
                    console.log(buffers, powerLevel, bufferDuration, bufferSampleRate);
                    // 录音实时回调，大约1秒调用12次本回调
                    //可实现波形绘制，频率计算等功能 实时上传发送数据
                    // if(this.wa)
                    this.wave && this.wave.input(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
                },
            });

            this.rec.open(() => {
                console.log('打开成功');
                if (this.$refs.recwave) {
                    this.wave = Recorder.WaveView({
                        elem: this.$refs.recwave,//父容器
                    })
                }
            }, (msg, isUserNotAllow) => {
                console.log((isUserNotAllow ? 'UserNotAllow，' : '') + '无法录音:' + msg);
            });

        },
        // 开始录音
        recStart() {
            if (!this.rec) {
                console.log('请先打开录音');
                return;
            }
            this.rec.start();
            this.isOpenVoice = true;
            console.log('已经开始录音');
        },
        // 结束录音
        recStop() {
            if (!this.rec) {
                console.log('请先打开录音');
                return;
            }
            this.rec.stop((blob, duration) => {
                // console.log('已经结束录音', blob, '时长:', duration + 'ms');
                this.rcBlod = blob;
                // 利用URL生成播放地址,此地址只可以本地使用,比如用于audio标签的src,或者a标签的href
                var locaAudiolUrl = window.URL.createObjectURL(blob);
                console.log('录音成功localUrl:', blob, locaAudiolUrl, '时长:', duration + 'ms');
                this.locaAudiolUrl = locaAudiolUrl;
                // this.sendAudioMessage(blob);
                this.sendMessage(3, this.locaAudiolUrl,blob);
                this.rec.close();
                this.rec = null;
            }, (msg) => {
                console.log('录音失败:', msg);
            });
        },
        // 输入框的自适应函数
        adjustTextareaHeight(e) {
            this.$refs.inputTextarea.style.height = e.target.scrollHeight + 'px';
        },
        onCompositionStart() {
            this.compositiLock = true
        },
        onCompositionEnd() {
            this.compositiLock = false
        },
        handleEnterKey(event) {
            if (!event.shiftKey && !this.compositiLock) {
                event.preventDefault()
                this.sendMessage(1, this.message)
            }
        },
            // 发送文本消息
        async sendMessage(type, content, blob) {
            // type 1 文本 2 图片 3 音频
            const that = this;
            console.log(that.message, 'that.message that.message ')
            if (type == 1) {
                if (that.message.trim() === "") return;
            }
            this.toBottom();
            this.sn = Date.now(); // 获取唯一标识
            this.autoScroll = true; // 开启自动滚动
            if (this.defaultQuestion) this.defaultQuestion = false;
            this.isSendFlag = true; // 正在发送消息
            // const Msg = this.message; // 存储一份数据
            let send_msg = ''
            if (type == 1) {
                send_msg = this.message
            } else if (type == 3) {
                send_msg = content
            }else if(type==2){ // 图片
                send_msg = content
            }
            this.message = "";
            const LastItem = {
                sn: that.sn, // 唯一标识
                send_msg: send_msg.replace(/\n/g, "<br>"),
                receive_msg: "",
                messageType: type,   // 1 文本 2 图片 3 音频
                aiLoading: true, // ai在思考中
                complete: false, // 数据是否接收完成
                error: false, // 是否有错误
            };
            this.msgList.push(LastItem);
            const formData = new FormData();
            formData.append("chat_type", this.chat_type);
            if (type == 3) { // 音频
                formData.append("audio_file", blob);
            } else if (type == 1) { // 文字
                formData.append("query", content);
            }else if(type==2){ // 图片
                formData.append("image_url", content);
            }
            if (this.chat_type == 2) {
                formData.append("resource_id", this.$store.state.coures.currentlyPlay.contentId);
            }
            if (this.chat_type == 4) {
                formData.append("course_ucode", localStorage.getItem('aiCourseId'));
            }
            that.ctrlAbout = new AbortController();
            fetchEventSource(`${URL.API}/ai-qa/v1/chat`, {
                openWhenHidden: true, // 用于解决切屏会重复链接，导致消息会发送多次
                method: "POST",
                headers: {
                    "Accept": "text/event-stream",
                    "token": localStorage.getItem("token"),
                },
                signal: that.ctrlAbout.signal,
                body: formData,
                onopen(response) {
                    console.info("eventSource open: ", response);
                },
                onmessage(event) {
                    LastItem.aiLoading = false;
                    // console.log('🚀 ~ file: index.vue ~ line 36 ~ onmessage ~ event', event, event.data)
                    if (event.data.includes("xmp1910:")) {
                        // 错误信息
                        LastItem.receive_msg = event.data.replace("xmp1910:", "");
                        LastItem.error = true;
                        LastItem.complete = true;
                        that.isSendFlag = false;
                        that.ctrlAbout.abort();
                        return;
                    } else if (event.data == "xmp1911") {
                        // 正常接收完成
                        console.log(that.lastData.list, "that.lastData")
                        if (that.lastData.list) {
                            that.$set(that.msgList[that.msgList.length - 1], "courseList", that.lastData.list);
                        }
                        that.$set(that.msgList[that.msgList.length - 1], "courseList", that.lastData.list);
                        LastItem.complete = true;
                        that.isSendFlag = false;
                        // 成功之后满足某些条件可以使用AbortController关闭连接
                        that.ctrlAbout.abort();
                        that.toBottom(); // 兜底接受完消息后，在最底部展示
                        return;
                    }
                    // 利用对象地址引用 更新消息
                    // LastItem.receive_msg += event.data.replace(/\\n/g, "<br>");
                    if (event.data.includes("msg_type")) {
                        let jsonString = "{" + event.data + "}";
                        // 替换掉jsonString单引号为双引号
                        jsonString = jsonString.replace(/'/g, '"');
                        jsonString = jsonString.replace(/None/g, '""');
                        let lastData = JSON.parse(jsonString);
                        that.lastData = lastData;
                        console.log(that.lastData, "that.lastData++++++++++++")
                        if (lastData.msg_type == 'audio') {
                            console.log(lastData.send_audio_url, "lastData.send_audio_url")
                            LastItem.receive_audio_url = lastData.receive_audio_url;
                            LastItem.receive_msg = lastData.receive_audio_url;
                        }
                        // this.$set(this.msgList[this.msgList.length - 1], "courseList", lastData.list);
                    } else {
                        LastItem.receive_msg += event.data.replace(/\\n/g, "<br>");
                    }


                },
                onerror(error) {
                    console.error("eventSource error: ", error);
                    LastItem.aiLoading = false;
                    LastItem.error = true;
                    LastItem.complete = true;
                    that.isSendFlag = false;
                    that.ctrlAbout.abort();
                    // 提示用户刷新页面

                },
            });
        },
        recPlay() {
            if(!this.rcBlod){
                console.log('请先录音');
                return;
            }
            var url = window.URL.createObjectURL(this.rcBlod);
            var audio = document.createElement('audio');
            audio.controls = true;
            document.body.appendChild(audio);
            audio.src = url;
            audio.style.display = "none";
            audio.play();

            // 注意不用的时候需要revokeObjectURL，否则会内存泄漏
            audio.onended = function(){
                window.URL.revokeObjectURL(url);
                audio.parentNode.removeChild(audio);
            };
        },
        // 获取历史消息
        async getHistory() {
            return new Promise(resolve => {
                this.refreshing = true
                const data = {
                    sn: this.msgList[0] && this.msgList[0].sn || "",
                    page_num: 1,
                    page_size: 10,
                    chat_type:this.chat_type+"" || '1',//类型:1-通用助手(aI名师) 2-视频助手（学习中心） 3-隐患排查助手
                    resource_id:(this.chat_type==2)?this.contentId:undefined,
                    course_ucode:(this.chat_type==4)?localStorage.getItem('aiCourseId'):undefined
                }
                this.$api.gpt.getHistory(data).then(res => {
                    if (res.data.length == 0) return (this.NoMore = true)
                    let arr = res.data.reverse()
                    this.msgList.unshift(...arr)
                    if(this.msgList && this.msgList.length){
                        this.msgList = this.msgList.map(item=>{
                            let send_msg = '';
                            let receive_msg = '';
                            if(item.base_type==1){// base_type 1 文本 2 图片 3 音频
                                send_msg = item.query,
                                receive_msg = item.receive_msg
                            }else if(item.base_type==2){
                                send_msg = item.image_url,
                                receive_msg = item.receive_msg
                            }else if(item.base_type==3){
                                send_msg = item.send_audio_url,
                                receive_msg = item.receive_audio_url
                            }
                            if(item.course_recommend_json){
                                const obj = JSON.parse(item.course_recommend_json);
                                item.courseList = obj?.list;
                            }
                            return{
                                ...item,
                                complete:true,
                                messageType:item.base_type,
                                send_msg:send_msg,
                                receive_msg:receive_msg,
                            }
                        })
                    }
                    this.refreshing = false
                    resolve()
                })
            })
        },
        // 获取推荐课程
        async getCourseRecommend(Msg) {
            const res = await this.$api.gpt.courseRecommend({
                sn: this.sn,
                session_id: this.session_id,
                query: Msg,
            })
            this.$set(this.msgList[this.msgList.length - 1], 'courseList', res.data)
        },
        // 获取推荐问题
        getQuestionRecommend() {
            // const res = await this.$gptApi.gpt.getQuestionRecommend()
            if(this.chat_type){
                this.questionRecommendList = this.ConstData.GPT_TYPE_RECOMMEND[+this.chat_type]
            }
        },
        // 点击了推荐话术
        changeRecommend(question) {
            if (this.isSendFlag) return
            if (this.chat_type == 3) { // 隐患排查
                this.defaultQuestion = false;
                this.sendMessage(2, question, ''); // 传图片地址
            } else {
                this.message = question;
                this.defaultQuestion = false;
                this.sendMessage(1, this.message, '');
            }
        },
        // 点击了预设问题
        clickPresetQuestion(){
            this.$api.gpt.preset_question({
                type:this.chat_type || 1,//类型:1-通用助手(aI名师) 2-视频助手（学习中心） 3-隐患排查助手
                resource_id:(this.chat_type==2)?this.contentId:undefined
            }).then(res => {
                if(res.code==0){
                    this.show_preset_question = 0;
                }
            })
        },
        // 是否显示预设问题
        showPresetQuestion(){
            this.$api.gpt.show_preset_question({
                type:this.chat_type || 1,//类型:1-通用助手(aI名师) 2-视频助手（学习中心） 3-隐患排查助手
                resource_id:(this.chat_type==2)?this.contentId:undefined
            }).then(res => {
                if(res.data){
                    this.show_preset_question = res.data.status; //0:隐藏 1:显示
                }
            })
        },
        // 清除历史记录
        async clearContext() {
            if(this.isSendFlag) return
            const res = await this.$api.gpt.clearContext({
                chat_type:this.chat_type || 1,//类型:1-通用助手(aI名师) 2-视频助手（学习中心） 3-隐患排查助手
                resource_id:(this.chat_type==2)?this.contentId:undefined,
                course_ucode:(this.chat_type==4)?localStorage.getItem('aiCourseId'):undefined
            })
            if (res.message == 'success') {
                this.show_preset_question = 1 ;
                this.$message.success('清除成功')
                this.sn = ''
                this.msgList = []
            }
        },
        // 关闭对话框口
        closeDrawer(){
            this.$emit('openAi',false)
        },

    },
}
</script>

<style lang="scss" scoped>
.recwaveVP {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 24px;
    border-radius:10px;
    box-sizing: border-box;
    border: none;
    resize: none;
    .recwaveV {
        flex: 1;
        height: 24px;
        box-sizing: border-box;
        border: none;
        resize: none;
    }
}
.chatGpt {
    height:100%;
    position: relative;
    box-shadow: -2px 4px 8px 0px rgba(0, 0, 0, 0.08);
    border-left: 1px solid #E5E8EB;
    .gtpBox {
        background: #ffffff;
        height: 100%;
        margin: auto;
        display: flex;
        .contentBox {
            flex: 1;
            position: relative;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            header{
                height:48px;
                align-items: center;
                justify-content: space-between;
                display: flex;
                padding:0 16px;
                border-bottom: 1px solid #E5E8EB;
                .header-left{
                    align-items: center;
                    display: flex;
                }
                img{
                    width:24px;
                    height:24px;
                    margin-right:7px;
                }
                .title{
                    color:#333;
                    font-weight:600;
                    margin-right:16px;
                }
                .tag{
                    font-size: 12px;
                    color:#C0C4CC;
                }
                .icon{
                    color:#979797;
                    font-size:12px;
                }
            }
            #scrollbar {
                overflow-y: auto;
                flex-grow: 1;
                box-sizing: border-box;
                // display: flex;
                // flex-direction: column;
                // justify-content: flex-end;
                &::-webkit-scrollbar{
                    width: 3px;
                    background-color: #fff;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: #fff;
                    -webkit-box-shadow: #fff;
                }
                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                    -webkit-box-shadow: #fff;
                }
                ::v-deep.el-scrollbar__wrap{
                    &::-webkit-scrollbar{
                        width: 10px;
                        height: 10px;
                        background-color: black;
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        background-color: #fff;
                        -webkit-box-shadow: #fff;
                    }
                    &::-webkit-scrollbar-track {
                        border-radius: 10px;
                        -webkit-box-shadow: #fff;
                    }
                }
                .chatListBox {
                    box-sizing: border-box;
                    padding:20px 16px 0;
                    .defaultQuestion {
                        font-size: 14px;
                        line-height: 20px;
                        padding:12px 18px;
                        border-radius:9px;
                        margin-bottom:16px;
                        background: linear-gradient( 180deg, #F8FAFF 0%, #EBF6FF 100%);
                        .header {
                            color: #303133;
                            margin-bottom:8px;
                            border-radius:0 8px 8px 8px;
                            display:inline-block;
                            font-weight:550;
                        }
                        .ul {
                            display: flex;
                            flex-wrap: wrap;
                            .li {
                                cursor: pointer;
                                color:#211F1F;
                                padding:12px;
                                box-sizing: border-box;
                                width:calc(33.3% - 6px);
                                margin-right: 8px;
                                border-radius: 5px;
                                background:#fff;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                &:nth-last-child(1){
                                    margin-right:0;
                                }
                                span{
                                    display:inline-block;
                                }
                                .btn{
                                    width:50px;
                                    color:#fff;
                                    text-align:center;
                                    background: #1A72FF;
                                    border-radius:32px;
                                    padding:4px 16px;
                                    margin-top: 9px;
                                }
                                img{
                                    width:100%;
                                    object-fit: cover;
                                    height:60px;
                                }
                            }
                        }
                    }
                }
            }
            .chat-input-container {
                background:#F4F5F7;
                padding:0 16px 16px;
                box-sizing: border-box;
                min-height: 184px;
                max-height:224px;
                .functionBlock {
                    height:48px;
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    .recommend {
                        display: flex;
                        align-items: center;
                        span {
                            margin-left: 9px;
                            font-size: 14px;
                        }
                        color: #303133;
                    }
                    .clear {
                        font-size: 26px;
                        display:flex;
                        align-items:center;
                        &:hover{
                            cursor:pointer;
                            opacity:.8;
                        }
                        .icon{
                            margin-right:8px;
                            font-size:22px;
                        }
                        .el-button {
                            font-weight: 400;
                            color: #303133;
                        }
                    }
                    .disableClear{
                        .icon{
                            color:#666;
                            cursor: not-allowed;
                        }
                    }
                }
                .chat-input {
                    border-radius: 8px;
                    box-sizing: border-box;
                    background: #fff;
                    overflow: hidden;
                    position: relative;
                    padding: 16px 16px 36px;
                    img {
                        width: 24px;
                        height: 24px;
                        vertical-align: middle;
                    }
                    textarea {
                        transition: all .3s ease; 
                        width:100%;
                        box-sizing: border-box;
                        border: none;
                        resize: none;
                        font-size: 14px;
                        overflow: hidden;
                        line-height: 20px;
                        min-height: 68px;
                        max-height: 118px; /* 文本区域的最大高度 */
                        background: #fff;
                        font-weight: 400;
                        color: #303133;
                        &:focus {
                            border: none !important;
                            box-shadow: none !important;
                        }
                    }
                    .operateBox{
                        position: absolute;
                        right:12px;
                        left:0;
                        bottom:6px;
                        z-index: 99;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }
                    .sendBox{
                        text-align: right;
                        background:#fff;
                        pointer-events:auto;
                        .icon{
                            cursor: pointer;
                            color:#333;
                            // pointer-events:auto;
                            font-size:24px;
                            margin-left:10px;
                        }
                        .blue-icon{
                            color:#1A72FF;
                        }
                        .disabledIcon{
                            color:#ccc!important;
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }
    }
}

div.redGpt{
    header{
        background: #fff;
    }
    .gtpBox{
        background: #f1f1f1;
        .contentBox{
            .chat-input-container .chat-input .sendBox .blue-icon{
                color:#FF5C61;
            }
            #scrollbar .chatListBox .defaultQuestion{
                background:#fff;
                .ul{
                    .li{
                        background: linear-gradient( 180deg, #FFF8F8 0%, #FFEBEB 100%);
                        .btn{
                            background: linear-gradient( 180deg, #FF959A 0%, #FF5C61 100%);
                        }
                    }
                }
            }
        }
    }

} 
</style>
