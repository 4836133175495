
export default [
    {
        path: '/',
        name: 'homeyj',
        meta: {
            weight: 0,
            login: false,
            gpt:{
                gptType: 1,
                text:'AI名师',
                imgUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/custom.png'
            }
        },
        component: () => import(/* webpackChunkName: 'homeyj' */ './page/index.vue'),
    },

    {
        path: '/home',
        name: 'homegw',
        meta: {
            weight: 0,
            login: false,
            gpt:{
                gptType: 1,
                text:'AI名师',
                imgUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/custom.png'
            }
        },
        component: () => import(/* webpackChunkName: 'homegw' */ './page/homegw.vue'),
    },

]