const urlName = "/standard-saas/v1";
export default (API) => ({
  // 学习中心的直播列表
  liveList(data, pageNum, pageSize) {
    return API({
      url: `${urlName}/liveUser/findMyLives?pageNum=${pageNum}&&pageSize=${pageSize}`,
      method: "post",
      data,
    });
  },

  saveHeaderInfo(data) {
    return API({
      url: `/live/v1/tiw/saveHeaderInfo`,
      method: "post",
      data,
    });
  },
});
