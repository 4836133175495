import api from "./../api";
import isOfficial from './common';
import {getConfigureValue} from './configure.js'
import URL from "./../config/url";
import router from './../router/index.js'
export const branchDomain = async()=>{
    let obj = {
        pc:'',
        h5:''
    }
    if(localStorage.getItem('branchDomain')){
        obj = JSON.parse(localStorage.getItem('branchDomain'))
    }else{
        const UserBranchList = localStorage.getItem("UserBranchList") && JSON.parse(localStorage.getItem("UserBranchList"));
        const branchUcode = localStorage.getItem('branchUcode') || UserBranchList[0]?.ucode;
        await api.custom.getdomainsByBranchUcode({
            branchUcode:branchUcode
        }).then(res=>{
            res.data.forEach((val)=>{
                if (val.frontendTemplateId == 4) obj.h5 = val?.domain;
                if (val.frontendTemplateId == 5) obj.pc = val?.domain;
            })
            localStorage.setItem('branchDomain',JSON.stringify(obj))
        })
    }
    return obj
}

// type: govt:政府/协会 ldt:企业  user:学员
export const jumpLogin = async(type)=>{
    const wdObj = getConfigureValue('yjgw_wd_base') && JSON.parse(getConfigureValue('yjgw_wd_base'));
    const wdDomain = wdObj?.domain
    if(isOfficial && type=='user'){
        const address = window.location.protocol + '//' + wdDomain +`/login?from=${type}`;
        window.open(address,'_self')
    }else{
        router.push({
            path:'/login',
            query:{
                from:type
            }
        })
    }
}

// type: govt:政府/协会 ldt:企业  user:学员
export const jumpRegister = async(type)=>{
    const wdObj = getConfigureValue('yjgw_wd_base') && JSON.parse(getConfigureValue('yjgw_wd_base'));
    const wdDomain = wdObj?.domain
     if(isOfficial && type=='user'){
        const address = window.location.protocol + '//' + wdDomain +`/register?from=${type}`;
        window.open(address,'_self')
    }else{
        router.push({
            path:'/register',
            query:{
                type:type
            }
        })
    }
}
// 跳转到管理端type
export const jumpAdmin = async(token)=>{
    window.open(
        URL.env
          ? `https://emergency-admin.${URL.env}.wdeduc.com?token=${token}`
          : `https://emergency-admin.wdeduc.com?token=${token}`
      ,'_self');
}

// 试用
const accountList = {
    ldt:{
        fat:{
            account:'18099302263',
            pwd:'wd123456'
        },
        uat:{
            account:'15210280464',
            pwd:'wd123456'
        },
        production:{
            account:'18099302263',
            pwd:'wd123456'
        }
    },
    govt:{
        fat:{
            account:'18030527783',
            pwd:'wd123456'
        },
        uat:{
            account:'15210280464',
            pwd:'wd123456'
        },
        production:{
            account:'18030527783',
            pwd:'wd123456'
        }
    }
}
export const jumpTry = async(type)=>{
    const env = URL.env ? URL.env :'production'
    window.open(
        URL.env
          ? `https://emergency-admin.${URL.env}.wdeduc.com/entrance/login?phone=${accountList[type][env]['account']}&pwd=${accountList[type][env]['pwd']}`
          : `https://emergency-admin.wdeduc.com/entrance/login?phone=${accountList[type][env]['account']}&pwd=${accountList[type][env]['pwd']}`
      ,'_self');
}