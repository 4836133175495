import API from './../libs/axios'
import _ from "lodash";

let apiArr1 = {}
let apiArr2 = {}
let apiArr = {}
const wdnRouters = require.context('ss-pc/src/api/modules', true, /\.js$/)
const Routers = require.context('./modules/', true, /\.js$/)

wdnRouters.keys().forEach(key => {

  let urlArr = key.split("/");
  let apiKey=urlArr[urlArr.length-1].split(".")[0]
  
  apiArr1[apiKey] = {
    ...wdnRouters(key).default(API)
  }
})

Routers.keys().forEach(key => {

  let urlArr = key.split("/");
  let apiKey=urlArr[urlArr.length-1].split(".")[0]
  
  apiArr2[apiKey] = {
    ...apiArr2[apiKey],
    ...Routers(key).default(API)
  }
})

apiArr = _.merge(apiArr1, apiArr2);

export default {
  ...apiArr
}
