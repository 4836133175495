export default [
    {
        path: '/emerCase',
        name: 'emerCase',
        meta: {
            weight: 0,
            login: false
        },
        component: () => import(/* webpackChunkName: 'courseData' */ './page/index.vue'),
    }
]