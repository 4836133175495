export default API => ({

  courseListByDomain({
    params,
    data
  }) {
    return API({
      url: '/standard-saas/v1/task/open/courseListByDomain',
      method: 'post',
      params,
      data
    })
  },
  // 列表
  getCourseList({
    params,
    data
  }) {
    return API({
      url: '/standard-saas/v1/task/open/courseList',
      method: 'post',
      params,
      data
    })
  },
  findAllByDomain({params,data}) {
    return API({
        url: `/standard-saas/v1/category/findAllByDomain` ,
        method:  'get',
        params,
        data
    });
},
  //查询所有可用分类
  findAll({params,data}) {
      return API({
          url: `/standard-saas/v1/category/findAll` ,
          method:  'get',
          params,
          data
      });
  },
  // 详情
  getCourseDetail({
    params,
    data
  }) {
    return API({
      url: '/standard-saas/v1/task/open/courseDetail',
      method: 'get',
      params,
      data
    })
  },
  // 获取课程大纲
  taskDetailWithoutLogin({
    params,
    data
  }) {
    return API({
      url: '/standard-saas/v1/taskUser/taskDetailWithoutLogin',
      method: 'get',
      params,
      data
    })
  },
  // 报名参加
  apply({
    params,
    data
  }) {
    return API({
      url: '/standard-saas/v1/task/open/apply',
      method: 'get',
      params,
      data
    })
  },
  getHotList({
    params,
    data
  }) {
    return API({
      url: '/standard-saas/v1/productCourse/open/hotList',
      method: 'get',
      params,
      data
    })
  },
  courseTopCount({
    data,
    params
  }) {
    return API({
      url: '/ssbi/v1/statistics/courseTopCount',
      method: 'post',
      params,
      data
    })
  },
})