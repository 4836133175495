export default (API) => ({
    // 病症案例列表
    getArticlesByDomain(params) {
        return API({
            url: '/cms/v1/article/getArticlesByDomain',
            params,
        })
    },
    // 病症案例分类
    getTreeByDomain(params) {
        return API({
            url: '/cms/v1/class/getTreeByDomain',
            params
        })
    }
});
