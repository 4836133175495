<template>
    <el-dialog
      title=""
      :visible.sync="dialogVisible.show"
      width="400px"
      :show-close="false"
      center
      :modal-append-to-body="false"
      :before-close="handleClose">
      <div class="content">
        <svg-icon icon-class="tips"></svg-icon>
        <p class="title">提示</p>
        <p class="text">确定要退出登录吗？</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="logOut">确 定</el-button>
      </span>
    </el-dialog>
</template>

<script>
export default {
  props:{
    dialogVisible:{
      type:Object,
      default:() => {
        return {
          show:false
        }
      }
    }
  },
  methods:{
    handleClose(){
      this.dialogVisible.show = false;
    },
    async logOut(){
      const params = { token: localStorage.getItem('token') }
      const res = await this.$api.usercenter.postAccounLogout({ params })
      if(res.data){
        localStorage.clear();
        window.location.reload();
      }else{
        // this.$message.warning('退出失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .el-dialog{
    border-radius: 10px;  
    .el-dialog__header{
      display: none;  
    }
    .el-dialog__body{
      padding: 40px 0;  
      .icon{
        font-size: 40px;
      }
      .title{
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 33px;  
        margin: 18px 0 16px;
      }
      .text{
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;  
      }
    }
    .el-dialog__footer{
      padding: 0 40px 40px;  
      .el-button{
        width: 148px;  
        height: 44px;
        border-radius: 22px;
      }
      .el-button+.el-button{
        margin-left: 24px;  
      }
    }
  }
  .content{
    text-align: center;  
  }
}
</style>