export default [
  {
    path: "/enterprise/train",
    name: "enterpriseTrain",
    meta: {
      weight: 0,
      login: false,
    },
    component: () =>
      import(/* webpackChunkName: 'enterpriseTrain' */ "./page/index.vue"),
  }
];
