export default [
    {
        path: '/courseData',
        name: 'courseData',
        meta: {
            weight: 0,
            login: false,
            gpt:{
                gptType: 1,
                text:'AI名师',
                imgUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/custom.png'
            }
        },
        component: () => import(/* webpackChunkName: 'courseData' */ './page/index.vue'),
    }
]