<template>
    <div class="MsgBox">
        <div class="msg" :class="[position,(rightObj.messageType == 2)?'rightImg':'',(rightObj.messageType == 3)?'audioBox':'']">
            <div class="content">
                <div class="textBox">
                    <div class="gptText">
                        <div v-if="MsgInfo.aiLoading" class="aiLoading">
                            正在努力思考，请耐心等待
                            <span>...</span>
                        </div>
                        <template v-else>
                        <!-- 'reply-evaluate' -->
                            <!-- <div :class="getEvaluateCls()" class="reply-evaluate" v-if="!isSendFlag && position == 'left'">
                                <template v-if="MsgInfo.reaction==1 || MsgInfo.reaction==2">
                                    <span>
                                        <svg-icon icon-class="approvaled" v-if="MsgInfo.reaction===1"></svg-icon>
                                        <svg-icon icon-class="opposeed" v-else></svg-icon>
                                    </span>
                                </template>
                                <template v-else>
                                    <span @click="toEvaluate(1)">
                                        <svg-icon icon-class="approval"></svg-icon>
                                    </span>
                                    <span class="line"></span>
                                    <span @click="toEvaluate(2)">
                                        <svg-icon icon-class="oppose"></svg-icon>
                                    </span>
                                </template>
                            </div> -->
                            <div v-if="(MsgInfo.messageType == 1) || (MsgInfo.messageType == 2) || (rightObj.messageType == 1)" v-html="content" :class="[flashLockBlock, MsgInfo.error ? 'red' : '']"></div>
                            <div v-if="MsgInfo.messageType == 3"  
                                :class="[ MsgInfo.error ? 'red' : '',]"  >
                                <div class="audioVleft" @click="playMu(MsgInfo,'revice-')">
                                    <audio  :id="'revice-'+MsgInfo.sn" controls="true" class="audio" :src="content" v-if="content"> </audio>
                                    <span class="icon"></span>
                                </div>
                            </div>

                            <div v-if="rightObj.messageType == 3"  
                                :class="[rightObj.error ? 'red' : '']">
                                <div class="audioV" @click="playMu(rightObj,'send-')">
                                    <audio  :id="'send-'+rightObj.sn" controls="true" class="audio" :src="content"> </audio>
                                    <span class="icon"></span>
                                </div>
                            </div>

                            <div v-if="rightObj.messageType == 2"  
                                :class="[flashLockBlock, rightObj.error ? 'red' : '']">
                                <div class="imageV">
                                    <img :src="rightObj.send_msg" />
                                </div>
                            </div>
                        </template>
                    </div>
                    <template v-if="position == 'left' && !MsgInfo.error">
                        <div class="courss" v-if="MsgInfo.complete && MsgInfo.courseList?.length > 0">
                            <div class="header">以下是相关课程推荐：</div>
                            <div class="ul">
                                <div class="li" v-for="(item, index) in MsgInfo.courseList" :key="index" @click="jumpCourse(item)">
                                    <img :src="item.coverImage" class="img" alt="" />
                                    <div class="bottom">
                                        <div class="titile">{{ item.name }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'msg',
    props: {
        // 位置 left/right
        position: {
            type: String,
            required: true,
            default: 'right',
        },
        // 内容
        content: {
            type: String,
            required: true,
            default: '',
        },
        // 是否在发送消息中
        isSendFlag:{
            type:Boolean,
            default:false
        },
        // // 头像
        // avatar: {
        //     type: String,
        //     // default: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/default-avatar.png',
        // },
        rightObj:{
            type: Object,
            default() {
                return {};
            },
        },
        MsgInfo: {
            type: Object,
            default() {
                return {
                    error: false, // 错误
                    complete: false, // 数据接收完成
                    aiLoading: false, // 是否在思考问题
                    courseList: [], // 推荐的课程
                }
            },
        },
    },
    computed: {
        flashLockBlock() {
            // 是否展示闪烁块
            if (this.position == 'left' && this.MsgInfo.complete == false) {
                return 'blink-container'
            } else {
                return ''
            }
        },
    },
    data(){
        return{
            endedListener: null,
        }
    },
    beforeDestroy() {
        const allAudio = document.querySelectorAll('audio');
        if(allAudio){
            // 暂停所有audio元素
            allAudio.forEach(audio => {
                audio.pause();
                audio.removeEventListener("ended", this.endedListener);
            });
        }
    },
    methods: {
        // 播放音频
        playMu(obj,type){
            // 获取页面上所有的audio元素
            const allAudio = document.querySelectorAll('audio');
            var currentAudio = document.getElementById(type+obj.sn); 
            if(allAudio){
               // 暂停所有audio元素
                allAudio.forEach(audio => {
                    if(audio != currentAudio){
                        audio.pause();
                        audio.parentNode.classList.remove(`playAudio`)
                    }
                });
            }
            if(currentAudio!==null){
                if(currentAudio.paused){
                    currentAudio.play();//
                    currentAudio.parentNode.classList.add("playAudio");
                    if (this.endedListener) {
                        currentAudio.removeEventListener("ended", this.endedListener);
                    }
                    this.endedListener = () => {
                        currentAudio.parentNode.classList.remove(`playAudio`)
                        // 移除事件监听器
                        currentAudio.removeEventListener("ended", this.endedListener);
                    };
                    // 在音频上添加事件监听器
                    currentAudio.addEventListener("ended", this.endedListener);

                }else{
                    currentAudio.parentNode.classList.remove(`playAudio`)
                    currentAudio.pause();// 这个就是暂停
                }
            }
        },
        jumpCourse(item) {
            this.$router.push({
                path: '/course/detail',
                query: {
                    ucode: item.ucode,
                },
            })
        },
        // 回答赞踩
        toEvaluate(reaction){
            if(this.MsgInfo.reaction===1 || this.MsgInfo.reaction===2) return
            const data = {
                sn:this.MsgInfo.sn,
                reaction:reaction, //0：未评价，1:赞 ，2：踩
                feedback:'',
            }
            this.$api.gpt.evaluate_llm(data).then(res=>{
                if(res.code==0){
                    this.$set(this.MsgInfo,'reaction',reaction)
                }
            })
        },
        // 评价
        getEvaluateCls(){
             let str = '';
            if(this.MsgInfo.reaction==1 || this.MsgInfo.reaction==2){
                str = 'evaluateed'
            }
            return str
        }
    },
}
</script>

<style lang="scss" scoped>
.MsgBox {
    padding-bottom: 16px;

    .msg {
        display: flex;
        align-items: flex-start;
        .content {
            padding:10px 16px;
            background:#EEF0F6;
            display: flex;
            align-items: center;
            &:hover{
                .textBox .gptText .reply-evaluate{
                    display:flex;
                }
            }
            .textBox {
                font-size: 14px;
                font-weight: 400;
                .gptText {
                    font-size: 14px;
                    font-weight: 400;
                    color: #ffffff;
                    div{
                        word-break: break-word;
                    }
                    .reply-evaluate{
                        position:absolute;
                        bottom:-28px;
                        right:0;
                        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.05);
                        background:#fff;
                        border-radius:4px;
                        padding:7px 0px;
                        width:74px;
                        box-sizing:border-box;
                        display:none ;
                        align-items:center;
                        span{
                            flex:1;
                            line-height:16px;
                            text-align:center;
                        }
                        .line{
                            width:1px;
                            height:8px;
                            flex:none;
                            background:#D8D8D8;
                        }
                        .icon{
                            font-size:16px;
                            &:hover{
                                color:#1A72FF;
                            }
                        }
                    }
                    .evaluateed{
                        width:33px;
                        height:33px;
                        padding:0;
                        border-radius:50%;
                        background:transparent;
                        box-shadow:none;
                        .icon{
                            font-size:33px;
                        }
                    }
                    .aiLoading {
                        display: flex;
                        align-items: center;
                        span {
                            height: 1em;
                            display: flex;
                            flex-direction: column;
                            line-height: 1;
                            overflow: hidden;
                            &::before {
                                content: '...\a..\a.';
                                white-space: pre-wrap;
                                animation: ellipsis 2s infinite step-end;
                            }
                        }
                    }
                    .red {
                        color: red!important;
                    }
                    @keyframes ellipsis {
                        33% {
                            transform: translateY(-2em);
                        }

                        66% {
                            transform: translateY(-1em);
                        }
                    }
                }
                .courss {
                    margin-top: 60px;
                    .header {
                        color: #303133;
                        font-size: 14px;
                        margin-bottom: 24px;
                    }
                    .ul {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr); /* 将容器分成3列，每列平均占据剩余空间 */
                        grid-row-gap: 8px; // 行间距
                        grid-column-gap: 8px; // 列间距。
                        .li {
                            cursor: pointer;
                            .img {
                                border-radius: 8px 8px 0px 0px;
                                width: 160px;
                                height: 90px;
                                object-fit: cover;
                                display: block;
                            }
                            .bottom {
                                padding: 12px 16px;
                                box-sizing: border-box;
                                // width: 268px;
                                height: 68px;
                                background: #ffffff;
                                border-radius: 0px 0px 8px 8px;
                                box-shadow: 0px 4px 16px 0px rgba(34, 98, 188, 0.1);
                                .titile {
                                    white-space: normal;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                    // width: 236px;
                                    font-size: 16px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #333333;
                                    line-height: 22px;
                                    text-shadow: 0px 4px 16px rgba(34, 98, 188, 0.1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .content{
        .playAudio{
            .icon{
                animation-name: voicePlay;
                animation-duration: 1s;
                animation-direction: normal;
                animation-iteration-count: infinite;
                animation-timing-function: steps(3);
            }
        }
    }
    .left {
        margin-bottom:12px;
        .content {
            padding:8px 15px 8px;
            border-radius: 0px 8px 8px 8px;
            .gptText {
                color: #303133 !important;
                line-height: 24px;
                position:relative;
            }
            .audioVleft{
                padding-right:10px;
                position:relative;
                .icon{
                    color:#303133 !important;
                    font-size:20px;
                    font-weight:600;
                    width:20px;
                    height:20px;
                    display: inline-block;
                    background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ai/left-voice.png') no-repeat right 0 center;
                    background-size: cover;
                }
                .audio{
                    position: absolute;
                    left:0;
                    right:0;
                    bottom:0;
                    top:0;
                    visibility: hidden;
                    width:40px;
                    height:20px;
                }
            }
        }
    }
    .right {
        flex-direction: row-reverse;
        .content {
            padding:8px 16px;
            line-height: 20px;
            background: #2e7fff;
            border-radius: 8px 0px 8px 8px;
            .imageV{
                img{
                    max-height:160px;
                    max-height:120px;
                    object-fit:cover;
                }
            }
            .audioV{
                position:relative;
                padding-left:10px;
                // &::after{
                //     width:24px;
                //     height:100%;
                //     left:0;
                //     content:'';
                //     position:absolute;
                //     background: #FF5C61;
                    
                // }
                .icon{
                    color:#ffff;
                    font-size:20px;
                    font-weight:600;
                    display: inline-block;
                    width:20px;
                    height:20px;
                    background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ai/right-voice.png') no-repeat left 0 ;
                    background-size: cover;
                }
                .audio{
                    position: absolute;
                    left:0;
                    right:0;
                    bottom:0;
                    top:0;
                    width:40px;
                    height:20px;
                    visibility: hidden;
                }
            }
            .playing{
                .audioV{
                    &::after{
                        // animation:widthAnimation 1.2s infinite;
                    }
                }
            }
        }
    }
    div.rightImg{
        .content{
            padding:0;
            background:transparent!important;
        }
    }
    div.audioBox{
        .content{
            &:hover{
                opacity:.7;
                cursor: pointer;
            } 
        }
    }
}
// 闪烁快动画
.blink-container:after {
    content: '▋';
    margin-left: 8px;
    animation: blinkAnimation 1s infinite;
}
div.redGpt{
    header{
        background: #fff;
    }
    .MsgBox{
        .left{
            .content{
                background: #fff;
            }
        }
        .right{
            .content{
                background: #FF5C61;
            }
        }
    }
}
@keyframes blinkAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
@keyframes voicePlay {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 100%;
  }
}
</style>
