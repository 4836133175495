<template>
    <div class="wd-empty-data radius30">
        <slot name="emptyImg">
            <img :src="src" />
        </slot>
        <slot name="emptyText">
            <p>{{ text }}</p>
        </slot>
        <slot name="emptyHandle"></slot>
    </div>
</template>
<script>
export default {
    name: "EmptyData",
    props: {
        src: {
            type: String,
            default: ()=>"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/emptyData.png",
        },
        text: {
            type: String,
            default: "暂无数据",
        },
    },
};
</script>
<style lang="less" scoped>
.wd-empty-data {
    background: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top:64px;
    img {
        width: 140px;
        height: auto;
    }
    p {
        color: #333333;
        font-size: 18px;
        line-height: 42px;
        text-align: center;
        margin-top: 12px;
    }
}
</style>
